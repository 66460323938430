import request from './request'

// 获取冰箱列表
export function getRefrigeratorList() {
  return request.get('/refrigerator/getAll')
}

// 新增/修改冰箱
export function addOrUpdateRefrigerator(data) {
  return request.post('/refrigerator/save', data)
}

// 删除冰箱
export function deleteRefrigerator(id) {
  return request.get(`/refrigerator/deleted?id=${id}`)
}

// 通过冰箱id查询标本盒
export function getBoxListByRefrigeratorId(rId) {
  return request.get(`/box/query?rId=${rId}`)
}

// 新增/修改标本盒
export function addOrUpdateBox(data) {
  return request.post('/box/save', data)
}

// 删除标本盒
export function deleteBox(id) {
  return request.get(`/box/deleted?id=${id}`)
}

// 通过标本盒id查询标本详情
export function getSpecimenListByBoxId(bId) {
  return request.get(`/box/info?bId=${bId}`)
}

// 人类标本详情修改/box/edit
export function UpdateSpecimen(data) {
  return request.post('/box/edit', data)
}

//老鼠标本详情修改/box/mouseEit
export function UpdateMouse(data) {
  return request.post('/box/mouseEit', data)
}


// 样本详情搜索页/box/queryHuman?gender=&age=&agem=&sbp=&abpm=&dbp=&dbpm=&smoking=&drinking=&ea1=&ea1m=&keyword=&disease=
export function getSpecimenListBySearch(data) {
  // 将查询字符串附加到请求 URL 中
  return request.get(`/box/queryHuman?${data}`);
}