<template>
  <div class="specimen-container">
    <div class="color-classify-btn" v-if="!isAuth('2')">
      <el-button type="success" @click="editClassify">
        {{ classifyButtonText = isEditingClassify ? '取消编辑分类' : '编辑分类' }}
      </el-button>
      <el-button type="primary" @click="editColor">
        {{ colorButtonText }}
      </el-button>
    </div>
    <el-table v-loading="dataListLoading" :data="indexColumn" style="width: 100%" border show-header :span-method="spanMethod">
      <!-- 第一列：从里到外顺序 -->
      <el-table-column label="从左到右" align="center" header-align="center">
        <el-table-column label="从里到外顺序" align="center" header-align="center">
          <el-table-column width="70" align="center" header-align="center">
            <template slot-scope="scope">
              <div style="cursor:default;">{{ scope.row.rowTitle }}</div>
            </template>
          </el-table-column>
          <el-table-column width="70" align="center" header-align="center">
            <template slot-scope="scope">
              <div class="my-center" style="cursor:default; height:150px;">{{ scope.row.catText }}</div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table-column>

      <el-table-column v-for="one in rowColumn" :key="one.id" :label="one.title" align="center" header-align="center">
        <el-table-column v-for="(colItem, index) in one.column" :key="index" :label="colItem" :width="index===0?200:250" align="center" header-align="center">
          <template slot-scope="scope">
            <div v-if="index === 0">
              <!-- 分类文字 -->
              <div class="my-center" style="width:100%; height:150px; border-left: 1.5px solid rgb(96,96,96);" @click="isEditingClassify && openClassifyDialog(one.colData[scope.$index][index])" :style="{ cursor: isEditingClassify ? 'pointer' : 'default' }"> {{  updateClassifyData(one.colData[scope.$index][index]) }} </div>
            </div>
            <div v-else :style="{ backgroundColor: getCellColor(one.colData[scope.$index][index]) }" style="cursor: pointer;" @click="isEditing && openCellEditDialog(one.colData[scope.$index][index])" @mouseover="showTooltip">
              <div class="my-center" @dblclick="navigateToNewPage(one.colData[scope.$index][index])" style="height:150px;border-left: 1.5px solid rgb(96,96,96);">{{  updateClassifyData(one.colData[scope.$index][index]) }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <el-dialog title="编辑单元格" :visible.sync="cellEditDialogVisible" width="500px" center>
      <el-form :model="cellEditForm" ref="cellEditForm" label-width="50px" v-loading="editFormLoading">
        <el-form-item label="描述" prop="text">
          <el-input v-model="cellEditForm.text"></el-input>
        </el-form-item>
        <el-form-item label="颜色" prop="color">
          <el-select v-model="cellEditForm.color" placeholder="请选择颜色">
            <el-option v-for="color in colors" :key="color" :label="color" :value="color">
              <span :style="{ backgroundColor: color, display: 'inline-block', width: '100%', height: '20px' }"></span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cellEditDialogVisible = false" :disabled="editFormLoading">取消</el-button>
        <el-button type="primary" @click="submitCellEdit" :disabled="editFormLoading">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑分类" :visible.sync="classifyDialogVisible" width="500px" center>
      <el-form :model="classifyForm" :rules="classifyRules" ref="classifyForm" label-width="50px" v-loading="editFormLoading">
        <el-form-item label="分类" prop="classify">
          <el-input v-model="classifyForm.classify"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="classifyDialogVisible = false" :disabled="editFormLoading">取消</el-button>
        <el-button type="primary" @click="submitClassify" :disabled="editFormLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRefrigeratorList, addOrUpdateRefrigerator } from '@/api/specimen'
export default {
  data() {
    return {
      indexColumn: [], // 五行一列
      rowColumn: Array.from({ length: 5 }, (_, colIndex) => ({
        id: colIndex + 1,
        title: `第${colIndex + 1}列`,
        column: ['分类', '1', '2', '3', '4'],
        colData: Array.from({ length: 20 }, (_, rowIndex) =>
          Array.from({ length: 5 }, (_, cellIndex) => `${rowIndex}_${colIndex * 5 + cellIndex}`)
        )
      })),// 四行五列
      // 蓝：#89B8E2 红：#A77979 黄：#D8B970 绿：#9BC083 粉：#DEC5C5 紫：#AE84A0 橙：#E3924F
      colors: ['#89B8E2', '#A77979', '#D8B970', '#9BC083', '#DEC5C5', '#AE84A0', '#E3924F'],
      cellColors: {},
      cellData: {}, // 存储单元格的主键ID和冰箱ID和分类
      isEditing: false, // 编辑模式
      colorButtonText: '编辑单元格数据', // 按钮文字
      isEditingClassify: false, // 分类编辑模式
      classifyButtonText: '编辑分类', // 分类按钮文字
      classifyDialogVisible: false, // 分类对话框可见性
      classifyForm: {
        classify: '',
        id: null,
        refrigeratorId: null,
        coordinates: ''
      },
      classifyRules: {
        classify: [
          { required: true, message: '请输入分类', trigger: 'blur' },
        ]
      },
      cellEditDialogVisible: false, // 单元格编辑对话框可见性
      cellEditForm: {
        text: '',
        color: '',
        coordinates: ''
      },
      editFormLoading: false,//编辑表单加载状态
      dataListLoading: false,//标本列表加载状态
    };
  },
  created() {
    this.initTable();
  },
  methods: {
    showTooltip(event) {
      event.target.title = "双击进入标本数据";
    },
    // 合并单元格
    spanMethod({ row, column, rowIndex, columnIndex }) {
      // 合并第一列的第一到第五条
      if (columnIndex === 0) {
        if (rowIndex % 5 === 0) {
          return {
            rowspan: 5, // 合并五行
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // 初始数据
    initData() {
      this.dataListLoading = true;
      getRefrigeratorList().then(res => {
        res.data.forEach(item => {
          const cellKey = `${item.row}_${item.line}`;
          this.$set(this.cellColors, cellKey, this.colors.indexOf(item.colour));
          this.$set(this.cellData, cellKey, { id: item.id, refrigeratorId: item.refrigeratorId, classify: item.classify });
        });
        this.dataListLoading = false;
      });
    },
    // 初始化表格
    initTable() {
      const rowNum = ['一', '二', '三', '四', '五'];
      for (let i = 0; i < 4; i++) {
        rowNum.forEach((column, index) => {
          if (index === 0) {
            this.indexColumn.push({
              rowTitle: `第${rowNum[i]}层`,
              catText: `第${column}条`,
            })
          } else {
            this.indexColumn.push({
              catText: `第${column}条`,
            })
          }
        })
      }
      this.initData();
    },
    editColor() {
      this.isEditing = !this.isEditing;
      this.colorButtonText = this.isEditing ? '取消编辑单元格数据' : '编辑单元格数据';
      if (this.isEditing) {
        this.$message({
          message: '编辑模式下单击单元格可弹出表单',
          type: 'success'
        });
      }
    },
    // 打开单元格编辑对话框
    openCellEditDialog(cellValue) {
      const cellInfo = this.cellData[cellValue];
      this.cellEditForm.text = cellInfo ? cellInfo.classify : '';
      this.cellEditForm.color = this.getCellColor(cellValue);
      this.cellEditForm.coordinates = cellValue;
      this.cellEditDialogVisible = true;
    },
    // 提交单元格编辑表单
    submitCellEdit() {
      this.$refs.cellEditForm.validate((valid) => {
        if (valid) {
          this.editFormLoading = true;
          const { text, color, coordinates } = this.cellEditForm;
          const [x, y] = coordinates.split('_');
          const cellInfo = this.cellData[coordinates];
          const payload = {
            classify: text,
            colour: color,
            ...(cellInfo && { id: cellInfo.id, refrigeratorId: cellInfo.refrigeratorId }),
            ...(!cellInfo && { row: x, line: y })
          };
          addOrUpdateRefrigerator(payload).then(response => {
            this.initData();
            this.$message({
              message: '更新成功',
              type: 'success'
            });
            this.cellEditDialogVisible = false;
            this.editFormLoading = false;
          }).catch(error => {
            console.error('提交失败', error);
            this.editFormLoading = false;
          });
        }
      });
    },
    getCellColor(cellValue) {
      const colorIndex = this.cellColors[cellValue];
      return colorIndex !== undefined ? this.colors[colorIndex] : '#f5f7fa';
    },
    // 跳转到标本数据页面
    navigateToNewPage(cellLineRow) {
      // 如果this.cellData[cellLineRow]为undefined，说明该单元格没有数据，提示用户无标本信息，无法查看
      if (!this.cellData[cellLineRow]) {
        this.$message({
          message: '无标本信息，无法查看',
          type: 'warning'
        });
        return;
      }
      this.$router.push(`specimen-data?rId=${this.cellData[cellLineRow].refrigeratorId}`);
    },
    // 编辑分类按钮点击事件
    editClassify() {
      this.isEditingClassify = !this.isEditingClassify;
      this.classifyButtonText = this.isEditingClassify ? '取消编辑分类' : '编辑分类';
      if (this.isEditingClassify) {
        this.$message({
          message: '编辑模式下单击分类单元格可编辑分类',
          type: 'success'
        });
      }
    },
    // 打开分类对话框
    openClassifyDialog(cellKey) {
      const cellInfo = this.cellData[cellKey];
      this.classifyForm.classify = this.cellData[cellKey] ? this.cellData[cellKey].classify : '';
      this.classifyForm.id = cellInfo ? cellInfo.id : null;
      this.classifyForm.refrigeratorId = cellInfo ? cellInfo.refrigeratorId : null;
      this.classifyForm.coordinates = cellKey;
      this.classifyDialogVisible = true;
    },
    // 提交分类表单
    submitClassify() {
      this.$refs.classifyForm.validate((valid) => {
        if (valid) {
          this.editFormLoading = true;
          const { classify, id, refrigeratorId, coordinates } = this.classifyForm;
          const [x, y] = coordinates.split('_');
          const payload = {
            classify,
            ...(id && { id }),
            ...(refrigeratorId && { refrigeratorId }),
            ...(!id && !refrigeratorId && { row: x, line: y })
          };
          console.log('提交分类数据', payload);

          addOrUpdateRefrigerator(payload).then(response => {
            this.initData();
            this.$message({
              message: '更新成功',
              type: 'success'
            });
            this.classifyDialogVisible = false;
            this.editFormLoading = false;
          }).catch(error => {
            console.error('提交失败', error);
            this.editFormLoading = false;
          });
        }
      });
    },
    //更新表格中数据
    updateClassifyData(cellValue) {
      const [x, y] = cellValue.split('_');
      const cellInfo = this.cellData[cellValue];
      return cellInfo ? cellInfo.classify : '';
    }

  }
};
</script>

<style scoped>
.specimen-container {
  width: 100%;
  height: 100%;
}
.color-classify-btn {
  margin-bottom: 15px;
}
.el-table th {
  background-color: #f5f7fa;
  color: #606266;
  font-weight: 600;
}
::v-deep .el-table .cell {
  padding: 0 0;
  height: 150px;
  /* line-height: 150px; */
  /* 文字超出换行，超出高度隐藏 */
}
::v-deep .el-table .el-table__cell {
  padding: 0 0;
  height: 150px;
}

::v-deep .el-table--border .el-table__cell:first-child .cell {
  padding: 0;
}
/* is-group下面的第三个tr设置display:none */
::v-deep .el-table__header .is-group tr:nth-child(3) {
  display: none;
}
.el-dropdown-link {
  cursor: pointer;
  width: 60px;
  height: 30px;
}
/* 设置每个层级的下边框颜色为蓝色 */
::v-deep .el-table__row:nth-child(5n) > td {
  border-bottom: 1.5px solid rgb(96, 96, 96);
}
::v-deep .el-table__row:nth-child(5n + 1) > td:nth-child(1) {
  border-bottom: 1.5px solid rgb(96, 96, 96);
}
/* 表头高度设置*/
::v-deep .el-table__header .is-group tr .cell {
  height: 60px;
  line-height: 60px;
}
::v-deep .el-table__header .is-group tr th {
  height: 60px;
  line-height: 60px;
}

/* 垂直居中 */
.my-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>